<template>
  <Default>
    <div class="container-bg-white container-space-default" style="min-height: calc(100vh - 200px)">
      <h1 class="title"><span class="border-left-line"></span>Chương trình khuyến mại</h1>
      <div class="flex flex-row justify-start items-center" style="margin: 0 70px">
        <div class="flex flex-col justify-center items-center p-3 rounded-md" style="width: 120px; height: 120px; flex-shrink: 0; background-color: rgba(190, 208, 235, 0.6)">
          <img :src="urlLogo" alt="logo" style="height: 60px; width: auto;">
          <div class="rounded-md" style="background-color: rgba(255, 200, 123, 0.6); padding: 6px 8px;"><span class="text-primary-500">Giảm 10%</span></div>
        </div>
        <div class="flex flex-row justify-space-between items-center ml-2 p-3 rounded-md w-full" style="height: 120px; background-color: rgba(190, 208, 235, 0.6)">
          <div class="text-base h-full flex flex-col" style="justify-content: space-evenly">
            <p><b>Giảm 10% khi thanh toán online</b></p>
            <a-progress :percent="percent" :showInfo="false" :strokeColor="'#FF8844'"/>
            <p style="color: #ee0033">
              <a-icon type="clock-circle" />
              Đã dùng {{percent}}%
            </p>
          </div>
          <div class="h-full flex flex-col items-end" style="justify-content: space-around">
            <div>
              <span class="margin-right-15">
                <span class="font-semibold">Nhập mã:</span>
                <span class="font-semibold text-base text-primary-500 ml-0.5">{{voucherCode}}</span>
              </span>
              <a-tooltip :visible="isDisplayCopyTooltip">
                <template slot="title">
                  Đã copy
                </template>
                <a-button style="width: 130px" type="danger" size="large" icon="copy" @click="onClickCopy">
                  Copy
                </a-button>
              </a-tooltip>
            </div>
            <a-button style="width: 130px" type="danger" size="large" icon="shopping-cart" @click="onClickPricing">
                Mua ngay
            </a-button>
          </div>
        </div>
      </div>
      <article class="text-base leading-8">
        <h2 class="mt-8">Hướng dẫn áp dụng Mã giảm giá</h2>
        <p>Tại màn hình thanh toán Online, bấm <b>Voucher giảm giá</b></p>
        <p>Bước 1: Nhập <b>Voucher giảm giá</b></p>
        <p>Bước 2: Bấm <b>Áp dụng</b></p>
        <img style="width: 400px; margin-top: 12px" :src="urlImage" alt="guide-step">
      </article>
    </div>
  </Default>
</template>

<script>
import Default from "@/layout/Default";
import {getUrlGPhotoSize} from "@/helper/GPhotoHelper";
import {LogoConstants} from "@/constant/beecost/BeeCostResourceConstant";
import {copyToClipboard} from "@/helper/StringHelper";
import moment from "moment";

export default {
  name: "PromotedView",
  components: {
    Default
  },
  data() {
    let percent = 70 + moment().diff(moment('15/09/2021', 'DD/MM/YYYY'), "day")
    percent = percent > 98 ? 98: percent
    return {
      voucherCode: null,
      urlLogo: getUrlGPhotoSize(LogoConstants.bee_market, '128'),
      urlImage: getUrlGPhotoSize(
        'https://lh3.googleusercontent.com/pw/AM-JKLWngkKz_VuxUQdUCm4Up_IYMiaYn7jDrzC-HkGJ2WcHTLYyP5I-3JtGPStDx3Ks8ZM03XogCwnGhAJBJpJPZSGlIOjsxFopPwXtZOhWwde1jjW-BUuuUBKR0P3qwuK-1k5sl1gbWaJTQ6F3e0t1Z3TM',
      ),
      percent: percent,
      isDisplayCopyTooltip: false
    }
  },
  mounted() {
    this.voucherCode = this.$el.getAttribute('vouchercode')
  },
  methods: {
    async onClickPricing() {
      this.$router.push({name: 'pricing'})
    },
    onClickCopy() {
      copyToClipboard(this.voucherCode)
      this.isDisplayCopyTooltip = true
      setTimeout(() => this.isDisplayCopyTooltip = false, 500)
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-progress-bg {
  height: 11px !important;
}
</style>
